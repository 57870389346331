import {
	Box,
	Divider,
	FormControl,
	Grid,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Select,
	makeStyles
} from '@material-ui/core';
import { ExitToApp, VpnKey } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getExchangeRate } from 'common/Utils/currency';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import { CurrencyContext } from 'context/currency';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import bgIcon from '../../assets/bg-flag.svg';
import usIcon from '../../assets/us-flag.png';
import i18n from '../../i18n';
import { Currency } from '../../interfaces/currency';
import { Languages } from '../../interfaces/language';
import firebase from '../firebase';
import SideMenu from './SideMenu';

const useStyles = makeStyles((theme) => ({
	icon: {
		minWidth: 30,
	},
	text: {
		whiteSpace: 'nowrap',
	},
	full: {
		width: '100%',
		borderRadius: theme.shape.borderRadius,
		transition: 'background-color 0.3s ease',
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	flag: {
		marginLeft: 10,
		width: 15,
		height: 15
	},
	selectorLanguages: {
		display: "flex",
		alignItems: "center"
	}
}));

function AccountMenu(props: RouteComponentProps): JSX.Element {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const currencyContext = useContext(CurrencyContext);
	const { accountMenu } = TranslationPrefixes;

	const [lang, setLang] = useState(i18n.language);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorSideEl, setAnchorSideEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSideClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorSideEl(event.currentTarget);
	};

	const handleSideClose = () => {
		setAnchorSideEl(null);
	};

	const logout = async () => {
		await firebase.logout();
		props.history.replace('/');
	};

	const openCredentialsForm = () => {
		setAnchorEl(null);
		history.push('/credentialsForm');
	};

	const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newLang = event.target.value as Languages;
		i18n.changeLanguage(newLang);
		setLang(newLang);
	};

	const handleCurrencyChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const newCurrency = event.target.value as Currency;
		const exchangeRate = await getExchangeRate(newCurrency)
		currencyContext?.setCurrency({
			currency: newCurrency,
			exchangeRate
		});
	};

	return (
		<Box paddingX="12px">
			<Divider
			></Divider>			<Grid style={{ minWidth: "300px", marginBottom: "10px", marginTop: "10px" }} container justifyContent="center" alignItems="center" spacing={1}>
				{/* <Grid item xs={4}>
					<Select
						className={classes.selectorLanguages}
						variant="outlined"
						value={lang}
						onChange={handleLanguageChange}
						disableUnderline
						displayEmpty
						fullWidth
					>
						<MenuItem value={Languages.BG}>{Languages.BG}
							<img className={classes.flag} src={bgIcon} alt="bg-flag" />
						</MenuItem>
						<MenuItem value={Languages.EN}>{Languages.EN}
							<img className={classes.flag} src={usIcon} alt="bg-flag" />
						</MenuItem>
					</Select>
				</Grid> */}
				{currencyContext && (
					<Grid item xs={8}  >
						<FormControl fullWidth>
							<Select
								variant="outlined"
								value={currencyContext.currency.currency}
								onChange={handleCurrencyChange}

								disableUnderline
								displayEmpty
							>
								<MenuItem value={Currency.USD}>{Currency.USD}</MenuItem>
								<MenuItem value={Currency.EURO}>{Currency.EURO}</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={2}  >
					<IconButton onClick={openCredentialsForm}><VpnKey /></IconButton>
				</Grid>
				<Grid item xs={2}  >
					<IconButton onClick={logout}><ExitToApp /></IconButton>
				</Grid>
				<Hidden mdUp>
					<Grid item xs={10} />
					<Grid item xs={2}>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={handleSideClick}
							disableRipple
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="long-menu"
							anchorEl={anchorSideEl}
							keepMounted
							open={Boolean(anchorSideEl)}
							onClose={handleSideClose}
						>
							<SideMenu />
						</Menu>
					</Grid>
				</Hidden>
			</Grid>
		</Box>
	);
}

export default withRouter(AccountMenu);
